.fade-line {
  opacity: 1;
  animation: fade-line 2s 2s ease-out forwards;
}

@keyframes fade-line {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
