.box {
  padding: 5px;
  border-radius: 1px;
  border-width: 1px;
  border-color: rgb(110, 110, 110);
  margin: 0 auto;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.home-box {
  position: absolute;
  margin: 0 auto;
  border-radius: 1px;
  opacity: 0;
  border-width: 1px;
  border-color: rgb(110, 110, 110);
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.5);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5em;
  padding: 10px;
}

.highlight {
  animation: blow 2s ease-out alternate-reverse;
}

.fade-final {
  opacity: 0;
  animation: fade-final 15s 4s ease-in-out forwards;
}

.fade-final-2 {
  opacity: 0;
  animation: fade-final 7s 19s ease-in forwards;
}

@keyframes fade-final {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes blow {
  0% {
    color: white;
    font-weight: 200;
  }

  50% {
    color: rgb(255, 160, 160);
    font-weight: 500;
  }

  100% {
    color: rgb(255, 0, 0);
    font-weight: 600;
  }
}
